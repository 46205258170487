
































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// interfaces
import { LessonLargeResource } from '@/store/types/schema'
// store
import AuthModule from '@/store/modules/auth'
// utils
import { declension } from '@/utils/functions'

@Component({
  components: {
    Tag,
  },
})
export default class LessonStatusCard extends Vue {
  @Prop({ default: true })
  private isLessonEnded!: boolean

  @Prop({ required: true })
  private days!: number

  @Prop({ required: true })
  private hours!: number

  @Prop({ required: true })
  private minutes!: number

  @Prop({ required: true })
  private lesson!: LessonLargeResource

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get broadcastUrlIsVK() {
    return !!this.lesson.broadcastUrl?.includes('vk.com')
  }

  private declNumber(num: number, cases: string[]) {
    return declension(num, cases)
  }
}
